import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "effects"
    }}>{`Effects`}</h1>
    <blockquote>
      <h5 parentName="blockquote" {...{
        "id": "not-familiar-with-observablesrxjs-v6"
      }}>{`Not familiar with Observables/RxJS v6?`}</h5>
      <p parentName="blockquote"><inlineCode parentName="p">{`Sigi`}</inlineCode>{` requires an understanding of Observables with RxJS v6. If you're new to Reactive Programming with RxJS v6, head over to `}<a parentName="p" {...{
          "href": "http://reactivex.io/rxjs/"
        }}>{`http://reactivex.io/rxjs/`}</a>{` to familiarize yourself first.`}</p>
    </blockquote>
    <p>{`An `}<strong parentName="p">{`Effect`}</strong>{` is the core primitive of `}<inlineCode parentName="p">{`Sigi`}</inlineCode>{`.`}</p>
    <p>{`It is a method in `}<inlineCode parentName="p">{`EffectModule`}</inlineCode>{` class which takes a stream of `}<inlineCode parentName="p">{`Payloads`}</inlineCode>{` and returns a stream of actions. `}<strong parentName="p">{`Payloads in, actions out.`}</strong></p>
    <p>{`It has roughly this type signature:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`<Payload>(action$: Observable<Payload>): Observable<Action>;
`}</code></pre>
    <p>{`While you'll most commonly produce actions out in response to some `}<inlineCode parentName="p">{`Payload`}</inlineCode>{` you received in, that's not actually a requirement! Once you're inside your `}<strong parentName="p">{`Effect`}</strong>{`, use any Observable patterns you desire as long as any output from the final, returned stream, is an action.`}</p>
    <p>{`The actions you emit will be immediately dispatched to their `}<inlineCode parentName="p">{`Module`}</inlineCode>{`.`}</p>
    <p>{`If emit action which actually associated with an `}<strong parentName="p">{`Effect`}</strong>{` itself, it may create an infinite loop:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`// DO NOT DO THIS
something(payload$: Observable<void>) {
  return payload$.map(() => this.getActions().something())
}
`}</code></pre>
    <h2 {...{
      "id": "access-appstate-in-effect"
    }}>{`Access `}<strong parentName="h2">{`AppState`}</strong>{` in `}<strong parentName="h2">{`Effect`}</strong></h2>
    <p>{`In `}<inlineCode parentName="p">{`EffectModule`}</inlineCode>{` instances, we could access the `}<inlineCode parentName="p">{`state$`}</inlineCode>{` property, which is a `}<inlineCode parentName="p">{`Observable`}</inlineCode>{` represent the `}<strong parentName="p">{`latest`}</strong>{` state.
We do not provide any way to access state directly in `}<strong parentName="p">{`Effect`}</strong>{`, because access state directly rather than the reactive way would cause problems in many scenarios, and many of these problems are hard to debug.
Think about the code snippets below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`@Module('B')
class ModuleB extends EffectModule<BState> {
  defaultState = {}

  constructor(private readonly httpClient: HttpClient) {
    super()
  }

  @Effect()
  addAndSync(payload$: Observable<number>) {
    return payload$.pipe(
      mergeMap((payload) => {
        const state = this.getState()
        return this.httpClient.get(\`/api/something\`, {
          body: {
            query: payload
          }
        }).pipe(
          map((response) => {
            if (state.expireIn < Date.now()) { // state here is staled
              return this.getActions().setResponse(response.body)
            } else {
              return this.getActions().setExpired(response.body)
            }
          }),
          catchError(...),
          startWith(this.getActions().setLoading(true)),
          endWith(this.getActions().setLoading(false)),
        )
      })
    )
  }
}
`}</code></pre>
    <p>{`If we access state in first `}<inlineCode parentName="p">{`mergeMap`}</inlineCode>{` by `}<inlineCode parentName="p">{`getState`}</inlineCode>{` directly, and reuse it in the `}<inlineCode parentName="p">{`map`}</inlineCode>{` operator then, the state may already stated when we really use it. It could simply fix by `}<inlineCode parentName="p">{`getState`}</inlineCode>{` in the `}<inlineCode parentName="p">{`map`}</inlineCode>{` operator, but it's truly hard to debug.`}</p>
    <p>{`So, always access state in `}<inlineCode parentName="p">{`reactive`}</inlineCode>{` way is a better practice:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`@Module('B')
class ModuleB extends EffectModule<BState> {
  defaultState = {}

  constructor(private readonly httpClient: HttpClient) {
    super()
  }

  @Effect()
  addAndSync(payload$: Observable<number>) {
    return payload$.pipe(
      mergeMap((payload) => {
        return this.httpClient.get(\`/api/something\`, {
          body: {
            query: payload
          }
        }).pipe(
          withLatestFrom(this.state$),
          map(([response, state]) => {
            if (state.expireIn < Date.now()) { // always latest state here
              return this.getActions().setResponse(response.body)
            } else {
              return this.getActions().setExpired(response.body)
            }
          }),
          catchError(...),
          startWith(this.getActions().setLoading(true)),
          endWith(this.getActions().setLoading(false)),
        )
      })
    )
  }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      